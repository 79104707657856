@import url(https://fonts.googleapis.com/css?family=Oswald|Work+Sans);
.con {
  cursor: pointer;
  display: inline-block;
  background-color: white;
  margin-top: 3px;
  z-index: 5;
  width: auto;
  padding: 15px;
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
}

.bar {
  display: block;
  height: 2px;
  width: 18px;
  background: #252525;
  margin: 3px auto;
}

.con .bar {
  background-color: #252525;
}

#middle {
  margin: 0 auto;
}

.bar {
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
}

.con #top.open {
  -webkit-transform: translateY(5px) rotateZ(45deg);
  transform: translateY(5px) rotateZ(45deg);
}

.con #bottom.open {
  -webkit-transform: translateY(-5px) rotateZ(-45deg);
  transform: translateY(-5px) rotateZ(-45deg);
}

.con #middle.open {
  width: 0;
}

.overlay {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 1);
  overflow-y: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  text-transform: uppercase;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 600px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

@font-face {
  font-family: 'Oswald', sans-serif;
  src: url('https://fonts.googleapis.com/css?family=Oswald');
}

@font-face {
  font-family: 'Work Sans', sans-serif;
  src: url('https://fonts.googleapis.com/css?family=Work+Sans');
}

:root {
  --themeColor1: #ebac9d;
  --themeColor2: #ff00e6;
  --themeColor3: #00fff9;
  --themeColor4: #2e98bc;
}

html {
  font-size: 100%;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  width: 100%;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
}

button:disabled {
  opacity: 0.5;
}

#content a,
#content a:visited {

  background: -webkit-gradient(
    linear,
    left top, left bottom,
    from(#ebac9d),
    to(#ebac9d)
  );

  background: linear-gradient(
    to bottom,
    #ebac9d 0%,
    #ebac9d 100%
  );

  background: -webkit-gradient(
    linear,
    left top, left bottom,
    from(var(--themeColor1)),
    to(var(--themeColor1))
  );

  background: linear-gradient(
    to bottom,
    var(--themeColor1) 0%,
    var(--themeColor1) 100%
  );
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 3px 3px;
  color: #000;
  text-decoration: none;
  padding-bottom: 0.25em;
}

#content a:hover {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .4s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23ebac9d' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-position: 0 100%;
  background-size: auto 6px;
  background-repeat: repeat-x;
  text-decoration: none;
}

