@import url('https://fonts.googleapis.com/css?family=Oswald|Work+Sans');

@font-face {
  font-family: 'Oswald', sans-serif;
  src: url('https://fonts.googleapis.com/css?family=Oswald');
}

@font-face {
  font-family: 'Work Sans', sans-serif;
  src: url('https://fonts.googleapis.com/css?family=Work+Sans');
}

:root {
  --themeColor1: #ebac9d;
  --themeColor2: #ff00e6;
  --themeColor3: #00fff9;
  --themeColor4: #2e98bc;
}

html {
  font-size: 100%;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  width: 100%;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
}

button:disabled {
  opacity: 0.5;
}

#content a,
#content a:visited {

  background: linear-gradient(
    to bottom,
    var(--themeColor1) 0%,
    var(--themeColor1) 100%
  );
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 3px 3px;
  color: #000;
  text-decoration: none;
  padding-bottom: 0.25em;
}

#content a:hover {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .4s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23ebac9d' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-position: 0 100%;
  background-size: auto 6px;
  background-repeat: repeat-x;
  text-decoration: none;
}
